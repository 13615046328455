<template>
    <div class="box">
        <nl-table
            :left-buttons="[
                {
                    label: $t('campaign.new'),
                    icon: 'plus-circle',
                    type: 'is-warning',
                    action: redirectToNew
                }
            ]"
            :columns.sync="columns"
            :get-data="getData"
            @then="handleThen"
        >
            <template #statut="{ props }">
                <span v-if="props.row.statut === 'a_confirmer'" class="tag is-info">
                    {{ $t('accompagnement.status.a_confirmer') }}
                </span>
                <span v-else-if="props.row.statut === 'a_faire'" class="tag is-warning">
                    {{ $t('accompagnement.status.a_faire') }}
                </span>
                <span v-else-if="props.row.statut === 'en_cours'" class="tag is-warning is-light">
                    {{ $t('accompagnement.status.en_cours') }}
                </span>
                <span v-else-if="props.row.statut === 'fait'" class="tag is-success">
                    {{ $t('accompagnement.status.fait') }}
                </span>
                <span v-else-if="props.row.statut === 'en_attente_credit'" class="tag is-danger">
                    {{ $t('accompagnement.status.en_attente_credit') }}
                </span>
                <span v-else-if="props.row.statut === 'en_attente_retour_client'" class="tag is-danger">
                    {{ $t('accompagnement.status.en_attente_retour_client') }}
                </span>
                <span v-else-if="props.row.statut === 'termine'" class="tag is-dark">
                    {{ $t('accompagnement.status.termine') }}
                </span>
                <span v-else class="tag is-dark">Inconnu</span>
            </template>
            <template #statutSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option :value="props.filters[props.column.field]" selected>
                        {{ $t('accompagnement.all') }}
                    </option>
                    <option value="a_confirmer">
                        {{ $t('accompagnement.status.a_confirmer') }}
                    </option>
                    <option value="a_faire">
                        {{ $t('accompagnement.status.a_faire') }}
                    </option>
                    <option value="en_cours">
                        {{ $t('accompagnement.status.en_cours') }}
                    </option>
                    <option value="fait">
                        {{ $t('accompagnement.status.fait') }}
                    </option>
                    <option value="en_attente_credit">
                        {{ $t('accompagnement.status.en_attente_credit') }}
                    </option>
                    <option value="en_attente_retour_client">
                        {{ $t('accompagnement.status.en_attente_retour_client') }}
                    </option>
                    <option value="termine">
                        {{ $t('accompagnement.status.termine') }}
                    </option>
                </b-select>
            </template>
            <template #actions="{ props }">
                <div class="d-flex justify-content-center" style="gap: 0.5rem">
                    <b-button
                        type="is-dark"
                        size="is-small"
                        @click="$router.push('/campaigns/details?id=' + props.row.id)"
                    >
                        {{ $t('global.details') }}
                    </b-button>
                    <b-button
                        v-if="user.role === 'admin' || $store.state.auth.fromAdmin"
                        type="is-warning"
                        size="is-small"
                        @click="$router.push('/admin/campaigns/details?id=' + props.row.id)"
                    >
                        Admin
                    </b-button>
                </div>
            </template>
        </nl-table>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable'
    import { mapActions, mapState } from 'vuex'

    export default {
        title: 'mes-accompagnements.title',
        name: 'Campaigns',
        components: { NlTable },
        data: function () {
            return {
                campaignsLength: null,
                columns: [
                    {
                        field: 'url',
                        label: 'Site',
                        centered: false
                    },
                    {
                        field: 'name',
                        label: this.$t('mes-accompagnements.sitecategory'),
                        centered: false
                    },
                    {
                        field: 'duree',
                        label: this.$t('mes-accompagnements.time'),
                        centered: false
                    },
                    {
                        field: 'budget_mensuel',
                        label: this.$t('mes-accompagnements.budget'),
                        centered: false
                    },
                    {
                        field: 'budget_global',
                        label: this.$t('mes-accompagnements.budgetglobal'),
                        centered: false
                    },
                    {
                        field: 'debut_accompagnement',
                        label: this.$t('mes-accompagnements.datestart'),
                        centered: false,
                        type: 'date',
                        defaultValue: 'ASAP'
                    },
                    {
                        field: 'fin_accompagnement',
                        label: this.$t('mes-accompagnements.dateend'),
                        centered: false,
                        type: 'date',
                        defaultValue: 'ASAP'
                    },
                    {
                        field: 'statut',
                        label: this.$t('global.status'),
                        centered: false,
                        custom: true,
                        customSearch: true
                    },
                    {
                        field: 'actions',
                        label: 'Actions',
                        centered: false,
                        custom: true,
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user
            })
        },
        methods: {
            ...mapActions('campaigns', {
                getData: 'getAuthCampaigns'
            }),

            handleThen(data) {
                if (this.campaignsLength === null) {
                    this.campaignsLength = data.length
                }
                if (data.length < 1 && this.campaignsLength < 1) {
                    this.snackNotif("Vous n'avez aucun accompagnement, vous allez être redirigé", 'is-warning')
                    this.redirectToNew()
                }
            },
            getCampaignPeriod(infos) {
                return new Date(JSON.parse(infos).period).toLocaleDateString('fr-fr', {
                    month: 'long',
                    year: 'numeric'
                })
            },
            redirectToNew() {
                this.$router.push({ name: 'AccompagnementClientNouveau' })
            }
        }
    }
</script>
